import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import Layout from "../components/layout";
import ContractForm from '../components/contact-form';
import productIcon from '../images/icons/product2.svg';
import mobiledevIcon from '../images/icons/mobiledev.svg';
import webdevIcon from '../images/icons/webdev.svg';
import contentIcon from '../images/icons/content.svg';
import interfaceIcon from '../images/icons/interface.svg';
import mvpIcon from '../images/icons/mvp.svg';
import botIcon from '../images/icons/bot.svg';
import messengerIcon from '../images/icons/messenger.svg';
import bg from '../images/home-bg.png';

const headline = 'Transform Your Vision Into Reality';

// markup
const IndexPage = ({siteMetadata}) => {
  return (
    <Layout pageClass="home-page">
      <Helmet>
        <title>{headline} - MatteCurve Technologies Private Limited</title>
        <meta name="description" content="Hire dedicated expert developers to build your product from MatteCurve. We are a team of experts in javascript technologies like react.js, node.js, angular.js etc with 10+ years of experience." />
        <meta name="keywords" content="Web Development, Mobile App Development, React, Node.js, Angular.js, Remote Developers" />
      </Helmet>
      <Header></Header>  
      <div style={{
        // backgroundImage: `url(${bg})`,
        // backgroundRepeat: `no-repeat`,
        // backgroundPosition: `center`,
        // backgroundSize: `cover`,
      }}>
        <div className="container">
          <div className="hero-wrapper row">
              <h1 className="animate__animated animate__fadeInUp">
              {headline}
              </h1>
              <div className="animate__animated animate__fadeInUp w-100">
                <h5>
                We transform your vision to build a great product or website into reality sooner than you expect. A strong team and strong performance makes it easier to achieve goals. 
                </h5>
              </div>
              <a href="/contact" className="our-work-btn btn-outline btn-outline--blue animated-button thar-three animate__animated animate__fadeInUp">Find Out How</a>
          </div>
        </div>
      </div>
      <main>
        <div className="container services-container animate__animated animate__fadeInUp">
          <div className="col col-12 mt-5">
              <div className="col-content">
                  <div className="section-heading">
                  Services
                  </div>
                  <div className="section-sub-heading">
                  We are a large team of highly expert javascript developers, ready to build products with your great ideas.
                  </div>
              </div>
          </div>
          <div className="row services-list">
            <div className="col-lg-4 col-md-6 col-sm-12 services-item">
              <div className='services-item-container'>
                <div className="service-icon">
                  <img src={productIcon} alt="Software Product Engineering" />
                </div>
                <div className="service-title">Software Product Engineering</div>
                <p className="service-description">
                We help our clients build a web presence, mobile presence 
                and connect with their customers. 
                Our software product engineering services include web development, 
                mobile app development, and MVP development.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 services-item">
              <div className='services-item-container'>
                <div className="service-icon">
                  <img src={webdevIcon} alt="Application Services" />
                </div>
                <div className="service-title">Application Services</div>
                <p className="service-description">
                We help our clients expand their existing teams to speed up the development and launch sooner.
                Our experienced team can do long-term maintenance for your applications.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 services-item">
              <div className='services-item-container'>
                <div className="service-icon">
                  <img src={mobiledevIcon} alt="Digital Growth" />
                </div>
                <div className="service-title">Digital Growth</div>
                <p className="service-description">
                With our digital marketing and search engine optimization expertise, our customers grow exponentially.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12 services-item">
              <div className="service-icon">
                <img src={mvpIcon} alt="MVP and Prototype Development" />
              </div>
              <div className="service-title">MVP and Prototype Development</div>
              <div className="service-description">An MVP is a lightweight early version of your product. We can build a quick MVP so that you will be able to get early feedback with the least effort and validate your idea. Hire expert MVP developers from MatteCurve.</div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 services-item">
              <div className="service-icon">
                <img src={botIcon} alt="Chatbot Development" />
              </div>
              <div className="service-title">Chatbot Development</div>
              <div className="service-description">We build a 100% custom AI chatbot as per your need, and automate it. Hire expert chatbot developers from MatteCurve.</div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 services-item">
              <div className="service-icon">
                <img src={messengerIcon} alt="Messaging App Development" />
              </div>
              <div className="service-title">Messaging App Development</div>
              <div className="service-description">We have expert developers to build secure messaging apps as per your needs. Hire expert messaging app developers from MatteCurve.</div>
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 topic-why-us-section">
            <div className="col col-12 mt-5 animate__animated animate__fadeInUp">
                <div className="col-content">
                    <div className="section-heading">
                    Company overview
                    </div>
                    <div className="section-sub-heading">
                    MatteCurve is a technology and engineering consultancy for businesses from India.
                    MatteCurve collaborates to deliver the best technology and engineering solutions that can scale your business by 10X. 
                    MatteCurve offers end-to-end software development services and has clients across the globe. 
                    Hire a dedicated team from MatteCurve today!
                    </div>
                </div>
            </div>
            <div className="col col-12 col-md-12 mt-5 animate__animated animate__fadeInUp">
                <div className="col-content">
                    <h4>We are agile</h4>
                    <p>In the first week of onboarding a project we discuss with our clients to choose the working process, does he/she has available to communicate day to day with our developers or they want to communicate on a weekly basis. It does not make much difference for our expert developers. Being agile our main focus is to build small shippable features, release early and get feedback from our potential customers.</p>
                    <h4>A+ engineers building your products</h4>
                    <p>We hire A+ engineers through our unique hiring process. Our expert developers write secure well-documented code, write test cases and communicate every detail with the clients. Our engineers go through a process of understanding the requirement and resolve their queries first and then they start writing code.</p>
                    <h4>Build product with strong architecture and well written test cases</h4>
                    <p>We love writing test cases and we have a solid scalable architecture to start any project quickly. We don’t write code for just today but the code written by us can be extended easily in the future.</p>
                    <h4>Automated deployment process</h4>
                    <p>We have an automated process where it does not require manual intervention to deploy web or mobile apps. This helps our expert developers to focus on writing code instead of being worried about deployments. It saves your money.</p>
                    <h4>We have research first approach</h4>
                    <p>After onboarding your project, the first week is for research. It may not require if the client has well-written documents but in most cases it requires. We will do domain analysis, build domain communication architecture and break down the work we need to do to complete the project.</p>
                </div>
            </div>
            {/* <div className="col col-12 col-md-6 mt-5">
                <div className="col-content">
                    <img alt="Team Work To Succeed" src="https://images.unsplash.com/photo-1492366254240-43affaefc3e3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=902&q=80" />
                </div>
            </div> */}
          </div>
          <div className="row mt-5 contact-us-section">
            <div className="col mt-5">
                <ContractForm></ContractForm>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Layout>
  )
}

export default IndexPage;
